/**
 * Storage
 * handles getting and setting storage values
 */

const PREFIX = "player.";

export const StorageKey = {
  VOLUME: "volume",
  MUTED: "muted",
  MUTED_PREVIEW: "muted_preview",
};

function prefix(key: string): string {
  return `${PREFIX}${key}`;
}

function unserialize(value: string | null): unknown {
  if (value) {
    try {
      return JSON.parse(value) || undefined;
    } catch (_e) {
      //
    }
  }
}

function serialize(value: unknown): string | undefined {
  try {
    return JSON.stringify(value) || undefined;
  } catch (_e) {
    //
  }
}

/********************
 * Persistent storage
 ********************/

/**
 * Get value based on key
 */
export function getData<T = unknown>(key: string): T | undefined {
  return (unserialize(localStorage.getItem(prefix(key))) as T) || undefined;
}

/**
 * Set value on key
 */
export function setData(key: string, value: unknown) {
  const serialized: string | undefined = serialize(value);
  if (serialized === undefined) {
    removeData(key);
  } else {
    localStorage.setItem(prefix(key), serialized);
  }
}

/**
 * Remove value for key
 */
export function removeData(key: string) {
  localStorage.removeItem(prefix(key));
}

/*******************
 * Transient storage
 *******************/

export function getSessionData<T = unknown>(key: string): T | undefined {
  return (unserialize(sessionStorage.getItem(prefix(key))) as T) || undefined;
}

export function setSessionData(key: string, data: unknown) {
  const serialized: string | undefined = serialize(data);
  if (serialized === undefined) {
    removeSessionData(key);
  } else {
    sessionStorage.setItem(prefix(key), serialized);
  }
}

export function removeSessionData(key: string) {
  sessionStorage.removeItem(prefix(key));
}
