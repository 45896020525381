import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlaybackMode, PlaybackState, VisualElement, } from "@tv4/avod-web-player-common";
import { isChrome, isSupportedOS as isDesktopOS, } from "@tv4/avod-web-player-device-capabilities";
import { useCallback } from "react";
import { useControls, usePlayerState } from "../../providers/CoreProvider";
import { useLayoutSize, useVisuals } from "../../providers/LayoutProvider";
import { useIsChannel, useIsLiveEventWithoutAds, useIsMtvNews247, } from "../../providers/MetadataProvider";
import { LayoutSize } from "../../util/device";
import AirplayButton from "../AirplayButton";
import { useTracksMenu } from "../audio-text-tracks/TracksMenu/TracksMenu";
import { ChannelsButton } from "../ChannelsButton";
import ChromeCastButton from "../ChromeCastButton";
import CurrentProgramMetaData from "../CurrentProgramMetaData";
import CurrentTime from "../CurrentTime";
import FullscreenButton from "../FullscreenButton";
import GoToLiveButton from "../GoToLiveButton";
import GoToStartOverButton from "../GoToStartOverButton";
import LiveStatus from "../LiveStatus";
import PlayPauseButton from "../PlayPauseButton";
import ProgressBar from "../progress-bar/ProgressBar";
import RemainingTime from "../RemainingTime";
import SeekBackwardButton from "../SeekBackwardButton";
import SeekForwardButton from "../SeekForwardButton";
import VolumeController from "../VolumeController/VolumeController";
import { Body, Bottom, ButtonGroup, ButtonsContainer, ControlsContainer, Middle, ProgressContainer, Top, } from "./DefaultControls.styles";
export function DefaultControls() {
    const { isCasting, isLive, isAd, isPauseAd, isFullscreen, playbackMode, playbackState, } = usePlayerState();
    const size = useLayoutSize();
    const isChannelAsset = useIsChannel();
    const isMtvNews247 = useIsMtvNews247();
    const controls = useControls();
    const playing = playbackState === PlaybackState.PLAYING;
    const isLiveEventWithoutAds = useIsLiveEventWithoutAds();
    // when smallest layout we show some buttons in the header instead of here
    const isSmallestLayout = size === LayoutSize.SMALLEST;
    const includeFullScreenButton = !isSmallestLayout;
    const includeChromeCastButton = isChrome() && !isSmallestLayout;
    const isShowingAd = isPauseAd || isAd;
    const showSeekbuttons = !isShowingAd && !isChannelAsset;
    // startover streams have no DVR window, and doesn't handle pausing well
    const showPlayPauseButton = !isChannelAsset && playbackMode !== PlaybackMode.START_OVER;
    const isSeekableLiveStream = isLive && !isPauseAd && !isChannelAsset;
    const showStartOverButton = isSeekableLiveStream && !isMtvNews247 && !isSmallestLayout;
    const visuals = useVisuals();
    const showChannelsButton = visuals?.includes(VisualElement.CHANNELS_BUTTON);
    const { TracksButton, TracksMenu, tracksMenuOpen, closeTracksMenu } = useTracksMenu();
    const showMetaData = isFullscreen && [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size);
    const showLiveStatus = isLive || isMtvNews247;
    const showRemainingTime = !showLiveStatus && !isSmallestLayout;
    // whether to allow clicking on video to toggle playback
    const isClickableElement = !isAd && !isPauseAd && !isCasting && isDesktopOS() && !isChannelAsset;
    const clickHandler = useCallback(() => {
        // close tracks menu if open
        if (tracksMenuOpen) {
            closeTracksMenu();
        }
        // toggle playback
        else if (isClickableElement) {
            controls?.[playing ? "pause" : "play"]?.();
        }
    }, [isClickableElement, controls, playing, closeTracksMenu, tracksMenuOpen]);
    return (_jsxs(ControlsContainer, { "$compact": isSmallestLayout, children: [_jsx(Body, { "$isFullscreen": isFullscreen, "$isClickableElement": isClickableElement, onClick: clickHandler, children: showMetaData && (_jsxs(_Fragment, { children: [_jsx(Top, { children: _jsx(CurrentProgramMetaData, { hidden: false }) }), _jsx(Middle, {}), _jsx(Bottom, {})] })) }), _jsx(ProgressContainer, { "$compact": isSmallestLayout, children: !isShowingAd && !isChannelAsset && (_jsxs(_Fragment, { children: [_jsx(CurrentTime, {}), _jsx(ProgressBar, {}), showLiveStatus && _jsx(LiveStatus, {}), showRemainingTime && _jsx(RemainingTime, {})] })) }), _jsxs(ButtonsContainer, { children: [_jsxs(ButtonGroup, { "$position": "left", children: [showPlayPauseButton && _jsx(PlayPauseButton, {}), showStartOverButton && _jsx(GoToStartOverButton, {}), showSeekbuttons && !isSmallestLayout && (_jsxs(_Fragment, { children: [_jsx(SeekBackwardButton, {}), _jsx(SeekForwardButton, {})] }))] }), _jsx(ButtonGroup, { "$position": "center", children: isSmallestLayout ? (_jsxs(_Fragment, { children: [showSeekbuttons && _jsx(SeekBackwardButton, {}), showChannelsButton && _jsx(ChannelsButton, {}), showSeekbuttons && _jsx(SeekForwardButton, {})] })) : (_jsxs(_Fragment, { children: [TracksButton && _jsx(TracksButton, {}), showChannelsButton && _jsx(ChannelsButton, {})] })) }), _jsxs(ButtonGroup, { "$position": "right", children: [isSeekableLiveStream && (_jsx(GoToLiveButton, { liveEventWithoutAds: isLiveEventWithoutAds })), _jsx(AirplayButton, {}), includeChromeCastButton && _jsx(ChromeCastButton, {}), _jsx(VolumeController, {}), includeFullScreenButton && _jsx(FullscreenButton, {})] })] }), TracksMenu && _jsx(TracksMenu, {})] }));
}
