import { CoreEvents, } from "@tv4/avod-web-player-common";
import { TrackingManager, } from "@tv4/avod-web-player-tracking";
export class TrackingManagerProxy {
    trackingManager;
    constructor(trackers, trackerOptions) {
        this.trackingManager = new TrackingManager(trackers);
        this.trackingManager.setup(trackerOptions);
    }
    getTrackingManager() {
        return this.trackingManager;
    }
    on(eventType, data) {
        switch (eventType) {
            case CoreEvents.LOADING_PLAYBACK:
                this.trackingManager.onLoading();
                break;
            case CoreEvents.LOADED_PLAYBACK:
                this.trackingManager.onLoaded(data);
                break;
            case CoreEvents.START:
                this.trackingManager.onStart();
                this.trackingManager.onPlay(data);
                break;
            case CoreEvents.PAUSED:
                this.trackingManager.onPause(data);
                break;
            case CoreEvents.RESUME:
                this.trackingManager.onResume(data);
                break;
            case CoreEvents.RESET:
                this.trackingManager.onReset();
                break;
            case CoreEvents.SEEKING:
                this.trackingManager.onSeek(data);
                break;
            case CoreEvents.SEEKED:
                this.trackingManager.onSeeked(data);
                break;
            case CoreEvents.BUFFERING:
                this.trackingManager.onBuffering(data);
                break;
            case CoreEvents.BUFFERED:
                this.trackingManager.onBuffered(data);
                break;
            case CoreEvents.TIME_UPDATED:
                this.trackingManager.onTimeUpdate(data);
                break;
            case CoreEvents.ERROR:
            case CoreEvents.LOAD_PLAYBACK_ERROR:
                this.trackingManager.onError(data);
                break;
            case CoreEvents.ENDED:
                this.trackingManager.onEnded();
                break;
            case CoreEvents.BITRATE_CHANGED:
                this.trackingManager.onBitrateChanged(data);
                break;
            case CoreEvents.DROPPED_FRAMES:
                this.trackingManager.onDroppedFrames(data);
                break;
            case CoreEvents.BREAK_START:
                this.trackingManager.onAdBreakStart(data);
                break;
            case CoreEvents.BREAK_END:
                this.trackingManager.onAdBreakEnd(data);
                break;
            case CoreEvents.AD_LOADING:
                this.trackingManager.onAdLoading(data);
                break;
            case CoreEvents.AD_LOADED:
                this.trackingManager.onAdLoaded(data);
                break;
            case CoreEvents.AD_START:
                this.trackingManager.onAdPlaying(data);
                break;
            case CoreEvents.AD_PAUSED:
                this.trackingManager.onAdPaused(data);
                break;
            case CoreEvents.AD_RESUME:
                this.trackingManager.onAdResume(data);
                break;
            case CoreEvents.AD_TIME_UPDATED:
                this.trackingManager.onAdTimeUpdate(data);
                break;
            case CoreEvents.AD_BUFFERING:
                this.trackingManager.onAdBuffering(data);
                break;
            case CoreEvents.AD_BUFFERED:
                this.trackingManager.onAdBuffered(data);
                break;
            case CoreEvents.AD_ENDED:
                this.trackingManager.onAdEnded();
                break;
            case CoreEvents.AD_ERROR:
                this.trackingManager.onAdError(data);
                break;
            case CoreEvents.AD_CLICK:
                this.trackingManager.onAdClick(data);
                break;
            case CoreEvents.CDN_CHANGED:
                this.trackingManager.onCdnChanged(data);
                break;
        }
    }
    destroy() {
        this.trackingManager.destroy();
    }
}
