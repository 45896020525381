import { getUserId, Service, storage } from "@tv4/avod-web-player-common";

import { getAccessToken } from "./credentials";

export const MTVAssets = [
  { name: "[Clip - Long Form] Dogs", value: "3ef3e65f8a8f2520761f" },
  { name: "[Clip - Short Form] WRC", value: "23d96481761265447989" },
  { name: "[VOD] Huomenta Suomi", value: "c9204afe3da1ee10113b" },
  {
    name: "[VOD DRM + Skip Buttons] Hudson & Rex",
    value: "76ef8b76dd637c39d3ce",
  },
  {
    name: "[VOD DRM + HoH subtitles] Yökylässä Maria Veitola",
    value: "124f2fa12a75e1fca735",
  },
  {
    name: "[SVOD DRM Multi Audio + Multi Subs SUBSCRIPTION REQUIRED] Bullerbyn Movie",
    value: "be56bc5191eaefabcd2f",
  },
  {
    name: "[SVOD DRM Multi Subs SUBSCRIPTION REQUIRED] Sister Boniface",
    value: "2653955c73570d526f93",
  },
  {
    name: "[Live Scrubbable Channel with CSAI] MTV Uutiset 24/7",
    value: "9e6393959b5c6ccb71ea",
  },
  {
    name: "[Live DRM SUBSCRIPTION REQUIRED] MTV AVA",
    value: "5b6a0ec45c252bf1d4ba",
  },
  {
    name: "[NO ADS Live DRM SUBSCRIPTION REQUIRED] MTV MAX",
    value: "168a3a6d79976700163e",
  },
];

const MASTERKOCKEN_VOD_ASSET_ID = "8e93ff302400fdf6edec";

export const TV4PlayAssets = [
  { name: "[Clip] Krunegård", value: "d898bc5e5e592d2ea146" },
  { name: "[VOD] Sveriges Mästerkock", value: MASTERKOCKEN_VOD_ASSET_ID },
  { name: "[VOD] Jul med Ernst", value: "59735a0f174322378d64" },
  {
    name: "[VOD DRM] Söderkåkar",
    value: "b423237105b26f57a0a5",
  },
  {
    name: "[SVOD DRM Multi Audio] Bullerbyn Movie SUBSCRIPTION REQUIRED",
    value: "06dc9ccb2720574197e4",
  },
  {
    name: "[SVOD DRM Multi Subs SUBSCRIPTION REQUIRED] Sister Boniface",
    value: "08aaba1ffd613b73efb9",
  },
  {
    name: "[Live CMAF] Castens hörna Test Event TV4 NO DRM",
    value: "d7732d593eab42600c69",
  },
  {
    name: "[Live DRM CMAF] Mandelmann 24/7 Live TEST Channel",
    value: "20447232",
  },
  {
    name: "[Live CMAF] Ernst PlayTV Channel (VOD stitched Live NO DRM)",
    value: "74558fa8fc92149a5e71",
  },
  {
    name: "[Live DRM CMAF SUBSCRIPTION REQUIRED] Sjuan Live Channel",
    value: "3bac25bcd34c7e149f56",
  },
  {
    name: "[Live DRM CMAF SUBSCRIPTION REQUIRED] TV12 Live Channel",
    value: "1823232ee5c6c868154d",
  },
  {
    name: "[NO ADS Live DRM CMAF SUBSCRIPTION REQUIRED] TV4 Hits",
    value: "15c127efccf784983ffd",
  },
  {
    name: "[HLS MANIFEST VOD Multi Subs & Audio] Sintel",
    value: "https://bitmovin-a.akamaihd.net/content/sintel/hls/playlist.m3u8",
  },
  {
    name: "[MP4] Big Buck Bunny",
    value:
      "https://testcontent.eyevinn.technology/blender/BigBuckBunny/bbb_sunflower_1080p_60fps_normal.mp4",
  },
];

export const developmentConfig = {
  refreshTimeOut: 3600,
  MTVAuthEndpoint: "https://fin-alb.auth.a2d.tv/oauth",
  TV4AuthEndpoint: "https://avod-auth-alb.a2d.tv/oauth",
  AUTH_APP_HOST: "https://auth.a2d.tv",
  MTV_BRAND_PATH: "mtv",
  TV4_BRAND_PATH: "tv4",
  MTV_CLIENT: "mtv-web",
  TV4_CLIENT: "tv4-web",
  PATH_LOGIN: "login",
};

export const loadAssetsIntoSelect = (
  selectElement: HTMLSelectElement,
  service: Service
) => {
  const assets = getAssetsBasedOnService(service);
  assets.forEach((asset) => {
    const option = document.createElement("option");
    option.value = asset.value;
    option.text = asset.name;
    selectElement.appendChild(option);
  });
};

export const setCurrentlyActiveAsset = (
  selectElement: HTMLSelectElement,
  assetId: string,
  assets: { name: string; value: string }[] = TV4PlayAssets
) => {
  if (!assetId || !selectElement) return;

  const existingOption = selectElement.querySelector(
    `option[value="${assetId}"]`
  );

  if (!existingOption) {
    const option = document.createElement("option");
    option.value = assetId;
    option.textContent =
      assets.find((asset) => asset.value === assetId)?.name || assetId;
    selectElement.appendChild(option);
  }

  selectElement.value = assetId;
  updateQueryParameter("assetId", assetId);
};

export const hasMTVInLocation = () => {
  return window.location.pathname.toLowerCase().includes("/mtv");
};

export const getServiceFromURL = (): Service => {
  const pathName = window.location.pathname.toLowerCase();
  if (pathName.startsWith("/mtv")) return Service.MTV;

  return Service.DEFAULT;
};

export function updateLoginState() {
  const loginInfo = document.querySelector<HTMLSpanElement>(
    "#login-info"
  ) as HTMLSpanElement;

  const logoutButton = document.querySelector<HTMLButtonElement>(
    "#logout-button"
  ) as HTMLButtonElement;

  const accessToken = getAccessToken();
  const userId = accessToken ? getUserId(accessToken) : "n/a";
  logoutButton.style.display = "block";
  loginInfo.innerHTML = `Logged in as userId ${userId}

    (${storage.getSessionData("accountType")} account)`;
}

export function saveLoginDetails(accessToken: string, refreshToken: string) {
  const userId = getUserId(accessToken);
  storage.setSessionData("accessToken", accessToken);
  storage.setSessionData("refreshToken", refreshToken);
  storage.setSessionData("userId", userId);
  storage.setSessionData("accountType", hasMTVInLocation() ? "MTV" : "TV4");
}

export const updateQueryParameter = (name: string, value?: string | number) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(name, value?.toString() || "");
  window.history.pushState({}, "", currentUrl.toString());
};

export const getPlayerSettings = () => {
  const currentUrl = new URL(window.location.href);
  const usePreviewMode =
    currentUrl.searchParams.has("preview") ||
    storage.getSessionData("usePreviewMode") === true;
  const useDevCC =
    currentUrl.searchParams.has("useDevCC") ||
    storage.getSessionData("useDevCC") === true;
  const autoplay =
    currentUrl.searchParams.has("autoplay") ||
    storage.getSessionData("autoLoadAsset") === true;
  const hideMetadataOutsideFullscreen =
    currentUrl.searchParams.has("hideMetadataOutsideFullscreen") ||
    storage.getSessionData("hideMetadataOutsideFullscreen") === true;
  const useStartOver =
    currentUrl.searchParams.has("useStartOver") ||
    storage.getSessionData("useStartOver") === true;
  const startMuted =
    currentUrl.searchParams.has("muted") ||
    storage.getSessionData("mutedPlayback") === true;
  const showMobileMuteButton =
    currentUrl.searchParams.has("showMobileMuteButton") ||
    storage.getSessionData("showMobileMuteButton") === true;

  return {
    autoplay,
    hideMetadataOutsideFullscreen,
    showMobileMuteButton,
    startMuted,
    usePreviewMode,
    useStartOver,
    useDevCC,
  };
};

export const toggleQueryParameter = (name: string, value?: string | number) => {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);
  if (searchParams.has(name)) {
    searchParams.delete(name);
  } else {
    searchParams.set(name, value?.toString() || "");
  }

  currentUrl.search = searchParams.toString();
  window.history.pushState({}, "", currentUrl.toString());
};

export const getAssetId = (assetId: string, service: Service) => {
  if (assetId === MASTERKOCKEN_VOD_ASSET_ID && service === Service.MTV)
    return MTVAssets[0].value;

  return assetId;
};

export const getAssetsBasedOnService = (service: Service) => {
  switch (service) {
    case Service.MTV:
    case Service.MTVUUTISET:
      return MTVAssets;
    case Service.TV4Play:
      return TV4PlayAssets;
    default:
      return TV4PlayAssets;
  }
};
export const getCastApplicationId = (service: Service) => {
  switch (service) {
    case Service.TV4Play:
      return "9C60F407";
    case Service.MTV:
      return "34659BEB";
    default:
      return "9C60F407";
  }
};
export const getDEVCastApplicationId = (service: Service) => {
  switch (service) {
    case Service.TV4Play:
      return "DB44C06F"; // CC 3.0 receiver
    case Service.MTV:
      return "17DAB1B3"; // CC 3.0 receiver
    default:
      return "DB44C06F"; // CC 3.0 receiver
  }
};

export const getLoginIframeSource = () => {
  const isMTV = hasMTVInLocation();
  const BRAND_PATH = isMTV
    ? developmentConfig.MTV_BRAND_PATH
    : developmentConfig.TV4_BRAND_PATH;
  const CLIENT = isMTV
    ? developmentConfig.MTV_CLIENT
    : developmentConfig.TV4_CLIENT;

  return `${developmentConfig.AUTH_APP_HOST}/${BRAND_PATH}/${developmentConfig.PATH_LOGIN}?client=${CLIENT}`;
};

export const setupEventListenersForIframe = (authIframe: HTMLIFrameElement) => {
  window.addEventListener("message", (message) => {
    if (message.source === authIframe.contentWindow) {
      // Message from our auth iframe.
      const type = message.data.type;

      if (type === "loginSuccess") {
        const accessToken = message.data.payload.accessToken;
        const refreshToken = message.data.payload.refreshToken;

        saveLoginDetails(accessToken, refreshToken);
        window.location.reload();
      }
    }
  });
};
