import { API_ERROR, ERROR_CATEGORY, PLAYER_ERROR, USER_ERROR, } from "./ErrorConstants";
export var ErrorLevel;
(function (ErrorLevel) {
    ErrorLevel[ErrorLevel["CLIENT"] = 0] = "CLIENT";
    ErrorLevel[ErrorLevel["PLAYER"] = 1] = "PLAYER";
    ErrorLevel[ErrorLevel["PLATFORM"] = 2] = "PLATFORM";
    ErrorLevel[ErrorLevel["USER"] = 3] = "USER";
    ErrorLevel[ErrorLevel["UNKNOWN"] = 4] = "UNKNOWN";
})(ErrorLevel || (ErrorLevel = {}));
// Errors are ignored because they should be handled by the client, by showing a relevant message
export const IgnoredErrorsList = [
    PLAYER_ERROR.UNSUPPORTED_DEVICE,
    PLAYER_ERROR.AD_BLOCKER,
    USER_ERROR.GEO_LOCATION,
    USER_ERROR.GEO_LOCATION_PORTABILITY,
    USER_ERROR.UNAUTHORIZED,
    USER_ERROR.PROXY_BLOCKED,
    USER_ERROR.NOT_AUTHENTICATED,
    API_ERROR.STREAM_NOT_STARTED,
    API_ERROR.STREAM_LIMIT_QUOTA_EXCEEDED,
    API_ERROR.STREAM_LIMIT_SINGLE_STREAM_VIOLATION,
];
export class AWPError {
    /**
     * Which AWP package caused the error
     * @type {TContext}
     */
    context;
    /**
     * Error category
     * @type {string}
     */
    category;
    /**
     * Error code
     * @type {string}
     */
    code;
    /**
     * Human-readable message
     * @type {string}
     */
    message;
    /**
     * The raw error if any
     * @type {unknown}
     */
    raw;
    /**
     * Any additional error details that may need to propagate
     * @type {Record<string, unknown>}
     */
    details;
    /**
     * Whether the error is fatal (default: true)
     * @type {boolean}
     */
    fatal;
    /**
     * The error level, i.e. owner of the error
     * @type {ErrorLevel}
     */
    errorLevel;
    constructor({ context, code, category, message, raw, details, fatal = true, errorLevel, }) {
        this.context = context;
        this.message = message;
        this.category = category;
        this.code = code;
        this.raw = raw;
        this.details = details;
        this.fatal = fatal;
        this.errorLevel = errorLevel;
    }
}
/**
 * Ensure that the unknown value caught by try/catch or Promise.catch is an AWPError
 */
export function ensureAWPError(error) {
    if (error instanceof AWPError) {
        return error;
    }
    return new AWPError({
        context: "unknown",
        message: `Unhandled error: ${error}`,
        raw: error instanceof Error ? error : undefined,
        category: ERROR_CATEGORY.DEFAULT,
        errorLevel: ErrorLevel.UNKNOWN,
    });
}
