import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlaybackMode } from "@tv4/avod-web-player-common";
import styled from "styled-components";
import { usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { useIsChannel, useIsLiveEventWithoutAds, useIsMtvNews247, } from "../providers/MetadataProvider";
import { LayoutSize } from "../util/device";
import { useTracksMenu } from "./audio-text-tracks/TracksMenu/TracksMenu";
import ChromeCastButton from "./ChromeCastButton";
import CurrentTime from "./CurrentTime";
import { Body, ButtonGroup, ButtonsContainer, ControlsContainer, ProgressContainer, } from "./DefaultControls/DefaultControls.styles";
import FullscreenButton from "./FullscreenButton";
import GoToLiveButton from "./GoToLiveButton";
import GoToStartOverButton from "./GoToStartOverButton";
import LiveStatus from "./LiveStatus";
import PlayPauseButton from "./PlayPauseButton";
import ProgressBar from "./progress-bar/ProgressBar";
import RemainingTime from "./RemainingTime";
import SeekBackwardButton from "./SeekBackwardButton";
import SeekForwardButton from "./SeekForwardButton";
import VolumeController from "./VolumeController/VolumeController";
const ChromecastProgressContainer = styled(ProgressContainer) `
  justify-content: flex-end;
`;
export function ChromeCastControls() {
    const { isLive, isAd, isPauseAd, playbackMode } = usePlayerState();
    const size = useLayoutSize();
    const isChannel = useIsChannel();
    const isLiveEventWithoutAds = useIsLiveEventWithoutAds();
    const isStartOver = playbackMode === PlaybackMode.START_OVER;
    const isMtvNews247 = useIsMtvNews247();
    const isShowingAd = isPauseAd || isAd;
    const seekingEnabled = !isShowingAd &&
        ((isLiveEventWithoutAds && !isChannel) ||
            (!isLive && !isChannel) ||
            isMtvNews247);
    const { closeTracksMenu, TracksButton, TracksMenu } = useTracksMenu();
    const showLiveStatus = isLive || isMtvNews247;
    // when smallest layout we show some buttons in the header instead of here
    const isSmallestLayout = size === LayoutSize.SMALLEST;
    const showRemainingTime = !showLiveStatus && !isSmallestLayout;
    const isSeekableLiveStream = isLive && !isPauseAd && !isChannel;
    const showStartOverButton = isSeekableLiveStream && !isMtvNews247 && !isSmallestLayout;
    return (_jsxs(ControlsContainer, { "$compact": isSmallestLayout, children: [_jsx(Body, { onClick: closeTracksMenu }), _jsx(ChromecastProgressContainer, { "$compact": isSmallestLayout, children: seekingEnabled && (_jsxs(_Fragment, { children: [_jsx(CurrentTime, {}), _jsx(ProgressBar, {}), showLiveStatus && _jsx(LiveStatus, {}), showRemainingTime && _jsx(RemainingTime, {})] })) }), _jsxs(ButtonsContainer, { children: [_jsxs(ButtonGroup, { "$position": "left", children: [!isStartOver && !isChannel && _jsx(PlayPauseButton, {}), showStartOverButton && _jsx(GoToStartOverButton, {}), seekingEnabled && !isSmallestLayout && (_jsxs(_Fragment, { children: [_jsx(SeekBackwardButton, {}), _jsx(SeekForwardButton, {})] }))] }), _jsx(ButtonGroup, { "$position": "center", children: isSmallestLayout ? (_jsxs(_Fragment, { children: [seekingEnabled && _jsx(SeekBackwardButton, {}), seekingEnabled && _jsx(SeekForwardButton, {})] })) : (TracksButton && _jsx(TracksButton, {})) }), _jsxs(ButtonGroup, { "$position": "right", children: [isSeekableLiveStream && (_jsx(GoToLiveButton, { liveEventWithoutAds: isLiveEventWithoutAds })), !isSmallestLayout && _jsx(ChromeCastButton, {}), _jsx(VolumeController, {}), !isSmallestLayout && _jsx(FullscreenButton, {})] })] }), TracksMenu && _jsx(TracksMenu, {})] }));
}
