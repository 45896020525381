import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AssetType } from "@tv4/avod-web-player-common";
import { isSupportedOS as isDesktopOS } from "@tv4/avod-web-player-device-capabilities";
import styled from "styled-components";
import { usePlayerState } from "../providers/CoreProvider";
import { useLayoutSize } from "../providers/LayoutProvider";
import { useMetadata } from "../providers/MetadataProvider";
import { useTranslations } from "../providers/TranslationsProvider";
import { LayoutSize } from "../util/device";
import ContentRatings from "./ContentRatings";
export const Metadata = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ isFullscreen }) => (isDesktopOS() && isFullscreen ? 0.2 : 1)}em;

  overflow: hidden;
  transition: 0.5s ease-out;
  font-size: ${({ size }) => [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size) ? 0.7 : 1}em;
`;
export const Title = styled.span `
  display: -webkit-box;
  width: 100%;
  font-size: ${({ size }) => [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size) ? 1.5 : 1.8}em;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ size }) => [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size) ? 2 : 3};
  padding-right: 1em;
  -webkit-box-orient: vertical;
`;
export const Subtitle = styled.span `
  display: flex;
  align-items: center;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.7;
`;
const Divider = styled.div `
  margin: 0 0.5em;
  background: white;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  opacity: 0.7;
`;
const CurrentProgramMetaData = ({ hidden }) => {
    const { asset } = useMetadata();
    const { isFullscreen } = usePlayerState();
    const translations = useTranslations();
    const size = useLayoutSize();
    const showDivider = Boolean(asset?.seasonNumber && asset?.episodeNumber);
    const showSubtitle = Boolean(asset?.seasonNumber || asset?.episodeNumber);
    const contentRating = asset?.contentRating;
    const isClip = asset?.type === AssetType.CLIP;
    const metadataTitle = (isClip && asset?.title) || asset?.seriesTitle;
    return (_jsx(Metadata, { isFullscreen: isFullscreen, size: size, children: !hidden && (_jsxs("div", { children: [metadataTitle && _jsx(Title, { size: size, children: metadataTitle }), showSubtitle && (_jsxs(Subtitle, { children: [Boolean(asset?.seasonNumber) && (_jsxs("span", { children: [translations("program_metadata__season"), " ", asset?.seasonNumber] })), showDivider && _jsx(Divider, {}), Boolean(asset?.episodeNumber) && (_jsxs("span", { children: [translations("program_metadata__episode"), " ", asset?.episodeNumber] }))] })), contentRating && _jsx(ContentRatings, {})] })) }));
};
export default CurrentProgramMetaData;
