import "@tv4/avod-web-player-core/dist/index.css";
import "./player.scss";
import { AWPError, CoreEvents, ensureAWPError, ERROR_CATEGORY, ErrorLevel, EventEmitter, getEngineName, getImageProxyUrl, getRemoteConfigValue, getSelectedDrm, initRemoteConfig, isMtvOrUutisetService, PlaybackMode, PlaybackState, PLAYER_ERROR, PlayerMode, resolveMediaType, Service, setRemoteConfigValue, storage, UserTier, uuid, } from "@tv4/avod-web-player-common";
import { Core, getRemainingAdImmunity, } from "@tv4/avod-web-player-core";
import { canPlayVideo } from "@tv4/avod-web-player-device-capabilities";
import { PlaybackAPI, streamInfoService } from "@tv4/avod-web-player-http";
import { SkinController } from "@tv4/avod-web-player-skin";
import { TrackingManagerProxy } from "./TrackingManagerProxy";
import { getAssetMetadata, getMetadataAndMedia } from "./util/services";
import { createFinnpanelTracking, createGtmCoreTracking, createKilkayaTrackingIfNeeded, createNielsenTracking, createNpawTracker, createWireVideoTracking, } from "./util/webPlayerTracking";
const defaultOptions = {
    playerMode: PlayerMode.DEFAULT,
    service: Service.DEFAULT,
};
const DESTROYED_ERROR = "WebPlayer instance destroyed. Calls to this method after destroying the player indicates bugs in the player integration.";
export class WebPlayer extends EventEmitter {
    destroyed = false;
    core;
    skinController;
    playbackApi;
    trackingManagerProxy;
    npawSessionGroupId;
    allowDrmLicenseRefetch = true;
    options; // ! assigned in method called from constructor
    media;
    metadata;
    startOverMedia;
    originMedia;
    currentAssetId;
    adTags;
    noAds;
    userInitiatedPlayback = false;
    get CurrentAssetId() {
        return this.currentAssetId;
    }
    container;
    mutableState = {
        cancelled: false,
    };
    constructor(root, opts) {
        super();
        initRemoteConfig(opts?.service || defaultOptions.service);
        // Check localstorage cast receiver id override, for testing purposes
        // ex: localStorage.setItem("player.CCRAID", '"ASDF1234"')
        // Note that it must be set to a JSON.stringified string
        const CAST_ID_KEY = "CCRAID"; // Chromecast Custom Receiver App Id
        const castId = storage.getData(CAST_ID_KEY) || opts?.castId;
        this.updateOptions({
            ...defaultOptions,
            ...opts,
            castId,
        });
        this.playbackApi = new PlaybackAPI(this.options.playbackApiURL || getRemoteConfigValue("PLAYBACK_API_URL"), this.options.accessToken, this.options.playbackApiIncludeCredentials);
        this.container = this.createContainerElement();
        root.appendChild(this.container);
        this.core = new Core(this.container, this.coreOptionsFromPlayerOptions(this.options));
        this.core.onAll((eventType, data) => {
            this.emit(eventType, data);
            this.trackingManagerProxy?.on(eventType, data);
            this.core.notifyCoreEvent(eventType, data);
            switch (eventType) {
                case CoreEvents.PLAYBACK_MODE_CHANGE:
                    return this.handlePlaybackModeChange(data);
                case CoreEvents.USER_ACTIVE_CONFIRM:
                    this.core.getControls()?.play?.();
                    return this.skinController.update({ showInactivePrompt: false });
                case CoreEvents.USER_ACTIVE_DECLINE:
                    return this.skinController.update({ showInactivePrompt: false });
                case CoreEvents.CHROMECAST_SESSION_STARTED:
                    return this.reset();
                case CoreEvents.CHROMECAST_CONTENT_UPDATED:
                    return this.handleChromecastContentUpdate(data.contentId);
                case CoreEvents.CHROMECAST_SESSION_ENDED:
                    return this.resumePlaybackFromChromecast(data);
                case CoreEvents.DRM_LICENSE_ERROR:
                    return this.handleDrmLicenseError(data);
                case CoreEvents.AUTOPLAY_BLOCKED:
                    if (this.options.playerMode !== PlayerMode.PREVIEW)
                        return;
                    this.core.getControls()?.mute?.();
                    requestAnimationFrame(() => {
                        this.core.getControls()?.play?.();
                    });
                    break;
                case CoreEvents.ERROR:
                    if (this.destroyed)
                        return;
                    if (data && "error" in data && data.error?.fatal) {
                        this.reset();
                    }
                    break;
                default:
                    break;
            }
        });
        this.skinController = new SkinController({
            core: this.core,
            root: this.container,
            onPlayWhenEnded: () => this.restartAsset(true),
        });
        // SkinController will not accept all options in constructor
        this.updateSkinController();
    }
    coreOptionsFromPlayerOptions(playerOptions) {
        const coreOptionsProperties = [
            "muted",
            "accessToken",
            "refreshToken",
            "autoplay",
            "gdprConsent",
            "fullscreenElementId",
            "iosNativeFullscreen",
            "service",
            "playerMode",
            "adUuid",
            "castId",
        ];
        const coreOptions = {};
        for (const coreOption of coreOptionsProperties) {
            if (Object.hasOwn(playerOptions, coreOption)) {
                coreOptions[coreOption] = playerOptions[coreOption];
            }
        }
        return {
            ...coreOptions,
            fullscreenElementId: coreOptions.fullscreenElementId || this.container.id,
        };
    }
    resumePlaybackFromChromecast(data) {
        const { contentId, currentTime } = data;
        if (!contentId) {
            return;
        }
        this.load({
            assetId: contentId,
            startTime: currentTime,
            userInitiatedPlayback: false,
            useStartOver: this.core.getState().playbackMode === PlaybackMode.START_OVER,
        });
    }
    setupTracking(media, metadata, playbackMode, trackers) {
        if (!media || !metadata) {
            return;
        }
        this.discardTrackingManagerProxy();
        const kilkayaTracking = createKilkayaTrackingIfNeeded({
            trackingMetadata: metadata.tracking.linkPulse,
        });
        if (kilkayaTracking) {
            trackers.push(kilkayaTracking);
        }
        if (metadata.tracking.nielsen) {
            trackers.push(createNielsenTracking({
                trackingMetadata: metadata.tracking.nielsen,
                userInitiatedPlayback: this.userInitiatedPlayback,
            }));
        }
        if (this.options.playerMode !== PlayerMode.PREVIEW) {
            trackers.push(createWireVideoTracking());
        }
        const service = this.options.service || getRemoteConfigValue("SERVICE");
        if (isMtvOrUutisetService(service)) {
            trackers.push(createFinnpanelTracking(service));
        }
        this.trackingManagerProxy = new TrackingManagerProxy([
            // new DebugTracker(),
            createGtmCoreTracking({
                trackingMetadata: metadata.tracking.GA,
            }),
            ...trackers,
        ], this.getTrackerOptions(media, metadata, playbackMode));
    }
    getTrackerOptions(media, metadata, playbackMode) {
        return {
            sessionId: this.core.sessionId,
            media,
            metadata,
            playbackMode,
            accessToken: this.options.accessToken,
            playerMode: this.options.playerMode,
            npawPlayerEngineName: getEngineName(media, metadata),
            npawSessionGroupId: this.npawSessionGroupId,
        };
    }
    // This method is used to report PlaybackApi errors to npaw that occur before the TrackingManagerProxy has been created
    handleStartupMetadataAndMediaError(npawTracker, error) {
        console.log("Failed to get metadata and media", error);
        npawTracker.setup({
            npawPlayerEngineName: null, // define here to avoid typescript error, overridden with value from getTrackerOptions, but expected to be null still because media and metadata is likely not available when error happens.
            npawSessionGroupId: null, // define here to avoid typescript error, overridden with value from getTrackerOptions.
            ...this.getTrackerOptions(),
            assetId: this.currentAssetId,
        });
        const errorPayload = {
            currentTime: 0,
            duration: 0,
            error,
        };
        npawTracker.onError(errorPayload);
        this.core.emitCoreEvent(CoreEvents.ERROR, errorPayload);
    }
    updateOptions(options) {
        this.options = {
            ...this.options,
            ...options,
        };
        setRemoteConfigValue("ACCESS_TOKEN", this.options.accessToken || "");
    }
    async setOptions(options) {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.updateOptions(options);
        this.core.setOptions(this.coreOptionsFromPlayerOptions(this.options));
        this.playbackApi.setAccessToken(this.options.accessToken);
        this.trackingManagerProxy?.getTrackingManager().update({
            accessToken: this.options.accessToken,
        });
        this.updateSkinController();
    }
    updateSkinController() {
        this.skinController.update({
            poster: getImageProxyUrl(this.options.poster || this.metadata?.asset?.image),
            onBackClick: this.options.onBackClick,
            onCloseClick: this.options.onCloseClick,
            onExitClick: this.options.onExitClick,
            onVideoClick: this.options.onVideoClick,
            service: this.options.service,
            playerMode: this.options.playerMode,
            hideMetadataOutsideFullscreen: this.options.hideMetadataOutsideFullscreen,
            hideSubtitlesMenu: this.options.hideSubtitlesMenu,
            showMobileMuteButton: this.options.showMobileMuteButton,
            playerProgressTintColor: this.options.playerProgressTintColor,
        });
    }
    createContainerElement() {
        const container = document.createElement("div");
        container.id = "container";
        container.classList.add("avod-web-player-container");
        return container;
    }
    getChromeCastManager() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        return this.core.getChromeCastManager();
    }
    restartAsset(userInitiatedPlayback) {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.setOptions({ autoplay: true });
        this.load({
            assetId: this.currentAssetId,
            enableVisuals: this.skinController.getEnabledVisuals(),
            userInitiatedPlayback,
        });
    }
    async getAssetMetadata(assetId) {
        const metadata = await getAssetMetadata({
            assetId,
            service: this.options.service,
            playbackApi: this.playbackApi,
            mutableState: this.mutableState,
        });
        if (!metadata)
            return;
        // These properties only exist on the play response, not the metadata response
        // so they should be undefined always, but if there are exceptions where the metadata response
        // still sends them, it will pick that
        metadata.asset.isStitched ??= this.metadata?.asset?.isStitched;
        metadata.asset.streaminfoUrl ??= this.metadata?.asset?.streaminfoUrl;
        return metadata;
    }
    async handleChromecastContentUpdate(assetId) {
        const metadata = await this.getAssetMetadata(assetId);
        if (this.destroyed || !metadata)
            return;
        this.updateChromeCastAssetMetadata(metadata);
        this.currentAssetId = assetId;
    }
    async updateChromeCastAssetMetadata(metadata) {
        if (metadata.userTier !== UserTier.SVOD && !metadata.asset.hideAds) {
            this.core.getChromeCastManager()?.emitClientSideAdMarkers(metadata);
        }
        this.metadata = metadata;
        this.skinController.update({ asset: metadata.asset });
    }
    async load({ assetId, src, startTime, enableVisuals, useStartOver, adTags, noAds, userInitiatedPlayback, }) {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.validateAsset(assetId, src);
        this.adTags = adTags;
        this.noAds = noAds;
        this.userInitiatedPlayback = userInitiatedPlayback;
        // generate a new session id each time load is called, used for the wire tracking session
        this.core.generateTrackingSessionId();
        if (assetId) {
            await this.loadAsset(assetId, startTime, this.options.playerMode, useStartOver, adTags, noAds);
        }
        else if (src) {
            this.loadSrc(src, startTime);
        }
        if (this.destroyed)
            return;
        if (enableVisuals) {
            this.skinController.update({ enableVisuals });
        }
    }
    validateAsset(assetId, src) {
        if (!assetId && !src) {
            throw new AWPError({
                context: "player",
                message: "load() assetId or src not provided",
                category: ERROR_CATEGORY.DEFAULT,
                code: PLAYER_ERROR.WRONG_INPUT,
                errorLevel: ErrorLevel.CLIENT,
            });
        }
    }
    async loadAsset(assetId, startTime, playerMode, useStartOver, adTags, noAds) {
        this.reset();
        this.allowDrmLicenseRefetch = true; // Reset this flag when loading a new asset
        this.npawSessionGroupId = uuid(); // This should only change when consumer loads a new asset
        const npawTracker = createNpawTracker({ playerName: "web-player" });
        const trackers = [npawTracker];
        this.currentAssetId = assetId;
        let metadata;
        let media;
        let startOverMedia;
        let originMedia;
        const service = this.options.service || getRemoteConfigValue("SERVICE");
        try {
            const metadataAndMedia = await getMetadataAndMedia({
                assetId,
                service,
                playbackApi: this.playbackApi,
                playerMode,
                mutableState: this.mutableState,
            });
            if (metadataAndMedia) {
                metadata = metadataAndMedia.metadata;
                media = metadataAndMedia.media;
                startOverMedia = metadataAndMedia.startOverMedia;
                originMedia = metadataAndMedia.originMedia;
            }
        }
        catch (error) {
            if (this.destroyed)
                return;
            return this.handleStartupMetadataAndMediaError(npawTracker, error);
        }
        if (this.destroyed)
            return;
        this.media = media;
        this.metadata = metadata;
        this.startOverMedia = startOverMedia;
        this.originMedia = originMedia;
        if (!this.metadata || !metadata)
            return;
        const { streaminfoUrl } = this.metadata.asset;
        if (streaminfoUrl) {
            streamInfoService.init({
                url: streaminfoUrl,
                onUpdate: this.core.emitCoreEvent.bind(this.core, CoreEvents.STREAM_INFO_UPDATED),
            });
        }
        this.skinController.update({
            asset: metadata.asset,
            poster: getImageProxyUrl(this.options.poster || metadata.asset.image),
            service,
            thumbnails: media?.thumbnails,
        });
        if (await this.core.isCasting(playerMode)) {
            if (this.destroyed)
                return;
            this.handleCastSessionPlayback(assetId, startTime);
            return;
        }
        // TODO: Use a setting to achieve "current behavior" (necessary changes/best approach
        // need to be investigated)
        let initialPlaybackMode = PlaybackMode.DEFAULT;
        if (streaminfoUrl) {
            if (startOverMedia && useStartOver) {
                initialPlaybackMode = PlaybackMode.START_OVER;
            }
            else if (this.core.getState().playbackMode === PlaybackMode.ORIGIN) {
                initialPlaybackMode = PlaybackMode.ORIGIN;
            }
            else if (media?.isLive && media?.isStitched) {
                initialPlaybackMode = PlaybackMode.LIVE_DAI;
            }
        }
        const playbackModePayload = {
            playbackMode: initialPlaybackMode,
        };
        if (initialPlaybackMode === PlaybackMode.ORIGIN) {
            playbackModePayload.originStartTime = startTime;
        }
        this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, playbackModePayload);
        const initialMedia = {
            [PlaybackMode.START_OVER]: startOverMedia,
            [PlaybackMode.ORIGIN]: originMedia,
        }[initialPlaybackMode] || media;
        this.setupTracking(initialMedia, metadata, initialPlaybackMode, trackers);
        await this.loadCore({
            media: initialMedia,
            metadata,
            startTime,
            adTags,
            noAds,
        });
    }
    async handleCastSessionPlayback(assetId, startTime) {
        const castManager = this.core.getChromeCastManager();
        const sessionState = castManager?.getCurrentChromecastSessionState();
        if (!sessionState)
            return;
        const sessionAssetId = sessionState.content?.contentId;
        if (!sessionState.session ||
            (sessionAssetId && sessionAssetId === assetId)) {
            if (!sessionState.session) {
                console.warn("Chromecast is connected, but there is no session, did chromecast crash?", { sessionState });
            }
            return;
        }
        this.currentAssetId = assetId;
        this.changeChromecastProgram(assetId, startTime);
    }
    async loadCore(options) {
        /**
         * core.load will initialize pause ads which relies on skin being rendered,
         * so if core.load is called before skin has rendered, execution will crash.
         * loadAsset will make requests to load content before calling core.load,
         * so skin will have enough time to render.
         * loadSrc will directly call core.load with url before skin has had time to
         * render.
         * ideally core should not assume that skin has rendered arbitrary dom
         * content, but for now always wait for skin to complete render before
         * core.load is called.
         */
        try {
            await this.skinController.awaitRender();
            if (this.destroyed)
                return;
            await this.core.load(options);
        }
        catch (originalError) {
            if (this.destroyed)
                return;
            if (originalError instanceof AWPError &&
                this.originMedia &&
                options.media !== this.originMedia) {
                options.media = this.originMedia;
                // TODO: emit the error as non-fatal here, so that we can see it in npaw,
                //  but it must not count for the AFS
                const cachedPlaybackMode = this.core.getState().playbackMode;
                // Reset core before emitting PLAYBACK_MODE_SET, since it would
                // otherwise be reset to default.
                this.core.reset();
                this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, {
                    playbackMode: PlaybackMode.ORIGIN_FALLBACK,
                    ...(cachedPlaybackMode === PlaybackMode.START_OVER && {
                        originStartTime: 0,
                    }),
                });
                return this.loadCore(options);
            }
            if (this.destroyed)
                return;
            const error = ensureAWPError(originalError);
            this.emit(CoreEvents.LOAD_PLAYBACK_ERROR, { error });
        }
    }
    loadSrc(src, startTime) {
        this.reset();
        this.skinController.update({ asset: undefined });
        this.loadCore({
            media: {
                manifestUrl: src,
                type: resolveMediaType(src),
                isLive: false,
                isStartOver: false,
                isStitched: false,
            },
            startTime,
        });
    }
    async changeChromecastProgram(assetId, startTime) {
        const metadata = await this.getAssetMetadata(assetId);
        if (this.destroyed || !metadata)
            return;
        const { activeAudioTrack, playbackMode } = this.core.getState();
        const { accessToken, refreshToken } = this.options;
        const { title, streaminfoUrl } = metadata.asset;
        const remainingAdImmunity = getRemainingAdImmunity(metadata.asset.assetId, getRemoteConfigValue("AD_IMMUNITY_MAX_TIME"));
        await this.core.getChromeCastManager()?.cast({
            assetId,
            title,
            streaminfoUrl,
            currentTime: startTime,
            accessToken,
            refreshToken,
            playbackMode,
            remainingAdImmunity,
            preferredAudioLanguage: activeAudioTrack?.language,
        });
        if (this.destroyed)
            return;
        this.updateChromeCastAssetMetadata(metadata);
        this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, {
            playbackMode: PlaybackMode.DEFAULT,
        });
    }
    play() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.play?.();
    }
    pause() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.pause?.();
    }
    toggleFullscreen() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.toggleFullscreen?.();
    }
    enterFullscreen() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.enterFullscreen?.();
    }
    exitFullscreen() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        const controls = this.core.getControls();
        controls?.exitFullscreen?.();
    }
    showSkin() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.skinController.update({
            forceShowSkin: true,
        });
    }
    hideSkin() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.skinController.update({
            forceShowSkin: false,
        });
    }
    isFullscreen() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        return this.core.getState().isFullscreen;
    }
    isStartOver() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        return this.core.getState().playbackMode === PlaybackMode.START_OVER;
    }
    isPlaying() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        return this.core.getState().playbackState === PlaybackState.PLAYING;
    }
    static async isBrowserSupported(checkDrmSupport = false) {
        const supported = canPlayVideo();
        if (!supported)
            return false;
        if (checkDrmSupport) {
            return !!(await getSelectedDrm());
        }
        return true;
    }
    inactiveUsageDetected() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.skinController.update({
            showInactivePrompt: true,
        });
        this.core.getControls()?.pause?.();
    }
    handlePlaybackModeChange(playbackModePayload) {
        if (!this.media || !this.metadata)
            return;
        let { playbackMode, originStartTime } = playbackModePayload;
        const wasPlaying = this.isPlaying();
        let chosenMedia = this.media;
        // If yospace startover can be disabled on the back-end, then try to fallback on origin with startTime 0
        if (playbackMode === PlaybackMode.START_OVER &&
            (!this.startOverMedia || !this.metadata.asset.startOver)) {
            playbackMode = PlaybackMode.ORIGIN;
            originStartTime = 0;
        }
        if (playbackMode === PlaybackMode.START_OVER && this.startOverMedia) {
            chosenMedia = this.startOverMedia;
        }
        else if (playbackMode === PlaybackMode.ORIGIN && this.originMedia) {
            chosenMedia = this.originMedia;
            streamInfoService.update();
        }
        // If startover is requested when there's no startover media and we fallback to live, we change playback mode to reflect this.
        // We might actually want the fallback to be Origin in this case?
        if (playbackMode === PlaybackMode.START_OVER &&
            chosenMedia === this.media) {
            playbackMode = PlaybackMode.LIVE_DAI;
        }
        this.core.reset();
        this.setupTracking(chosenMedia, this.metadata, playbackMode, [
            createNpawTracker({ playerName: "web-player" }),
        ]);
        this.core.emitCoreEvent(CoreEvents.PLAYBACK_MODE_SET, {
            playbackMode,
        });
        this.loadCore({
            media: chosenMedia,
            metadata: this.metadata,
            startTime: originStartTime,
            autoplay: wasPlaying,
            adTags: this.adTags,
            noAds: this.noAds,
        });
    }
    async handleDrmLicenseError(drmLicenseError) {
        if (!this.currentAssetId || !this.media?.license)
            return; // This function should only be called when there is a DRM license error for currently loaded media, so if media doesn't contain a license, we should do nothing
        if (!this.allowDrmLicenseRefetch)
            return this.core.emitCoreEvent(CoreEvents.ERROR, drmLicenseError);
        // Reset immediately to kill the ongoing session
        this.core.reset();
        this.allowDrmLicenseRefetch = false; // Only allow license refetch once per top-level load or when the license has expired
        // TODO this timeout needs to be saved and cleared on destroy/reset
        window.setTimeout(() => {
            this.allowDrmLicenseRefetch = true;
        }, 10 * 60 * 1000); // Allow license refetch again after 10 minutes, when it's expected that the last license fetched has expired
        let media;
        try {
            const metadataAndMedia = await getMetadataAndMedia({
                assetId: this.currentAssetId,
                service: this.options.service,
                playbackApi: this.playbackApi,
                playerMode: this.options.playerMode,
                mutableState: this.mutableState,
            });
            if (metadataAndMedia) {
                media = metadataAndMedia.media;
            }
        }
        catch (playbackApiError) {
            if (this.destroyed)
                return;
            const errorPayload = {
                ...drmLicenseError,
                error: playbackApiError,
            };
            return this.core.emitCoreEvent(CoreEvents.ERROR, errorPayload);
        }
        if (this.destroyed)
            return;
        // If fetching the license was successful, update the license for all media (same license is shared by all)
        if (media?.license) {
            [this.media, this.originMedia, this.startOverMedia].forEach((existingMedia) => {
                if (existingMedia?.license) {
                    existingMedia.license = media?.license;
                }
            });
        }
        let chosenMedia = this.media;
        const wasPlaying = this.isPlaying();
        const playbackMode = this.core.getState().playbackMode;
        if (playbackMode === PlaybackMode.START_OVER && this.startOverMedia) {
            chosenMedia = this.startOverMedia;
        }
        else if ((playbackMode === PlaybackMode.ORIGIN ||
            playbackMode === PlaybackMode.ORIGIN_FALLBACK) &&
            this.originMedia) {
            chosenMedia = this.originMedia;
        }
        this.loadCore({
            media: chosenMedia,
            metadata: this.metadata,
            startTime: drmLicenseError.currentTime,
            autoplay: wasPlaying,
            adTags: this.adTags,
            noAds: this.noAds,
        });
    }
    discardTrackingManagerProxy() {
        this.trackingManagerProxy?.destroy();
        this.trackingManagerProxy = undefined;
    }
    reset() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        // Cancel ongoing playback api requests since it holds a reference
        // to this mutable object.
        this.mutableState.cancelled = true;
        // create a new mutableState object to reset the state
        // for the next playback session.
        this.mutableState = {
            cancelled: false,
        };
        this.core.reset();
        this.media = undefined;
        this.metadata = undefined;
        this.startOverMedia = undefined;
        this.discardTrackingManagerProxy();
        streamInfoService.reset();
    }
    destroy() {
        if (this.destroyed) {
            throw new Error(DESTROYED_ERROR);
        }
        this.destroyed = true;
        this.mutableState.cancelled = true;
        super.destroy();
        this.skinController.destroy();
        this.discardTrackingManagerProxy();
        this.core.destroy();
        streamInfoService.reset();
        this.container.remove();
    }
}
