import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { clamp, PlaybackMode, PlaybackState, } from "@tv4/avod-web-player-common";
import { isMobile } from "@tv4/avod-web-player-device-capabilities";
import { streamInfoService } from "@tv4/avod-web-player-http";
import { useCallback, useEffect, useRef, useState } from "react";
import useDragging from "../../hooks/useDragging";
import { useAdBreaks, useControls, usePlayerState, usePlayerStatePolling, } from "../../providers/CoreProvider";
import { useLayoutSettings } from "../../providers/LayoutProvider";
import { useThumbnailCues } from "../../providers/ThumbnailsProvider";
import { AdMarker, AdMarkers, Handle, Progress, ProgressLine, ProgressWrapper, } from "./styles";
import { TimeThumbnailOverlay } from "./TimeThumbnailOverlay";
const ProgressBar = () => {
    const wrapperRef = useRef(null);
    const controls = useControls();
    const adBreaks = useAdBreaks();
    const [hovering, setHovering] = useState(false);
    const [scrubPercentage, setScrubPercentage] = useState(0);
    const playerState = usePlayerState();
    const thumbnailCues = useThumbnailCues();
    const { currentTime: preSeekCurrentTime, playbackMode, playbackState, preSeeking, isCasting, } = playerState;
    const { currentTime, streamInfo } = usePlayerStatePolling();
    const { playerProgressTintColor } = useLayoutSettings();
    let { duration } = playerState;
    if (streamInfo.duration && playbackMode !== PlaybackMode.DEFAULT) {
        duration = streamInfo.duration;
    }
    const getDragPercentage = (dragPosition) => {
        if (!wrapperRef.current)
            return 0;
        const progressbarContainer = wrapperRef.current.getBoundingClientRect();
        const handlePosition = dragPosition - progressbarContainer.left;
        return clamp(handlePosition / progressbarContainer.width) * 100;
    };
    const onDragChange = useCallback((evt) => {
        const percentage = getDragPercentage(evt.clientX);
        setScrubPercentage(percentage);
    }, []);
    useEffect(() => {
        if (!preSeeking)
            return;
        setScrubPercentage((preSeekCurrentTime / duration) * 100);
    }, [preSeeking, currentTime, preSeekCurrentTime, duration]);
    const onDragStop = useCallback(() => {
        const percentage = scrubPercentage;
        const secondsIntoVideo = (percentage / 100) * duration;
        controls?.seekTo?.(secondsIntoVideo);
    }, [scrubPercentage, controls, duration]);
    const { dragging, startDragging } = useDragging({ onDragChange, onDragStop });
    const onHoverMove = useCallback((percentage) => {
        if (preSeeking)
            return;
        setScrubPercentage(percentage ?? 0);
    }, [preSeeking]);
    const onPointerMove = useCallback((evt) => {
        const percentage = (evt.nativeEvent.offsetX / evt.currentTarget.clientWidth) * 100;
        setHovering(true);
        onHoverMove(clamp(percentage, 100));
    }, [onHoverMove]);
    const onPointerLeave = useCallback(() => {
        setHovering(false);
        onHoverMove(undefined);
    }, [onHoverMove]);
    let progress = 0;
    if (playbackMode === PlaybackMode.LIVE_DAI && !streamInfo.currentTime) {
        // default to showing at edge if seeking not allowed or in live mode before data is loaded
        // maybe this can/should apply to all live streams before we have the time,
        // but it's only really needed and tested for this specific case
        progress = 100;
    }
    else {
        let progressTime = streamInfo.currentTime ?? currentTime;
        if (isCasting && playbackMode !== PlaybackMode.LIVE_DAI) {
            progressTime = currentTime;
        }
        progress = (progressTime / duration) * 100;
    }
    let progressWrapperProps = {};
    if (playbackState !== PlaybackState.SEEKING) {
        progressWrapperProps = {
            onPointerDown: startDragging,
            onPointerMove: onPointerMove,
            onPointerLeave: onPointerLeave,
        };
    }
    const markerWidth = playbackMode !== PlaybackMode.DEFAULT ? 0 : undefined;
    const { watchedAdBreakStartTimes } = streamInfoService;
    return (_jsxs(ProgressWrapper, { ref: wrapperRef, grabbing: dragging, ...progressWrapperProps, children: [_jsxs(ProgressLine, { children: [_jsx(Progress, { style: {
                            width: `${dragging || preSeeking ? scrubPercentage : progress}%`,
                        }, playerProgressTintColor: playerProgressTintColor }), _jsx(AdMarkers, { "$markerWidth": markerWidth, children: adBreaks
                            // display no AdMarker if adBreak is empty and no ads will play
                            .filter((adBreak) => !adBreak.empty)
                            .map((adBreak, index) => {
                            // relative values between 0-1
                            const relativePosition = adBreak.timeOffset / duration;
                            const relativeDuration = adBreak.duration && adBreak.duration / duration;
                            const styles = {
                                left: `${relativePosition * 100}%`,
                            };
                            if (relativePosition === 0) {
                                styles.borderTopLeftRadius = "0.625em";
                                styles.borderBottomLeftRadius = "0.625em";
                            }
                            else if (relativePosition + (relativeDuration ?? 0) >= 1) {
                                styles.borderTopRightRadius = "0.625em";
                                styles.borderBottomRightRadius = "0.625em";
                            }
                            if (relativeDuration) {
                                // Make sure width is contrained to the container (most noticable when we get new ad breaks for streminfo)
                                const constrainedWidth = Math.min(1, relativePosition + relativeDuration) -
                                    relativePosition;
                                styles.width = `${constrainedWidth * 100}%`;
                            }
                            if (watchedAdBreakStartTimes.has(adBreak.timeOffset)) {
                                styles.opacity = 0.5;
                            }
                            return (_jsx(AdMarker, { "$width": markerWidth, style: styles }, `${adBreak.timeOffset}-${index}`));
                        }) }), _jsx(Handle, {})] }), (hovering || dragging) && scrubPercentage > 0 && (_jsx(TimeThumbnailOverlay, { left: isMobile() ? 50 : scrubPercentage, time: (scrubPercentage / 100) * duration, thumbnailCues: thumbnailCues }))] }));
};
export default ProgressBar;
