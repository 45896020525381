import { isbot, PlayerMode, request, } from "@tv4/avod-web-player-common";
import { RequestErrorCodes } from "@tv4/avod-web-player-common/src/request";
import { getBrowserName, isAndroid, isChromecast, isIOS, isIpadOS, } from "@tv4/avod-web-player-device-capabilities";
export const DEFAULT_CAPABILITIES = ["live-drm-adstitch-2", "yospace3"];
export function getDevice() {
    if (isChromecast())
        return "chromecast";
    if (isIOS() || isIpadOS())
        return "browser-ios";
    if (isAndroid())
        return "browser-android";
    return "browser";
}
export class PlaybackAPI {
    baseUrl;
    accessToken;
    includeCredentials;
    constructor(baseUrl, accessToken, includeCredentials = false) {
        this.baseUrl = baseUrl;
        this.accessToken = accessToken;
        this.includeCredentials = includeCredentials;
    }
    setAccessToken(accessToken) {
        this.accessToken = accessToken;
    }
    getAccessToken() {
        return this.accessToken;
    }
    async request(url) {
        if (await isbot()) {
            return new Promise(() => {
                // promise will never resolve for bot
            });
        }
        else {
            const [response, error] = await request(url, {
                method: "GET",
                headers: this.accessToken
                    ? {
                        "x-jwt": `Bearer ${this.accessToken}`,
                    }
                    : {},
                credentials: this.includeCredentials ? "include" : "same-origin",
            });
            if (error || !response) {
                throw error;
            }
            return response;
        }
    }
    async playRequest(id, options) {
        const additionalPathSegment = options.playerMode === PlayerMode.PREVIEW ? "/preview" : "";
        const url = new URL(`play/${id}${additionalPathSegment}`, this.baseUrl);
        url.searchParams.append("service", options.service);
        url.searchParams.append("device", options.device || getDevice());
        url.searchParams.append("protocol", options.protocol.join(","));
        url.searchParams.append("drm", options.drm ?? "");
        url.searchParams.append("browser", getBrowserName());
        url.searchParams.append("capabilities", DEFAULT_CAPABILITIES.join(","));
        return this.request(url).catch((e) => {
            // Immediate retry for status 0 requests, which are temporary
            // browser issues around fetching data.
            if ("code" in e && e.code === RequestErrorCodes.FAILED_TO_FETCH) {
                return this.request(url);
            }
            throw e;
        });
    }
    async assetDataRequest(id, options) {
        const url = new URL(`metadata/${id}`, this.baseUrl);
        url.searchParams.append("service", options.service);
        url.searchParams.append("device", options.device ?? getDevice());
        return await this.request(url);
    }
}
