import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PlaybackMode, VisualElement } from "@tv4/avod-web-player-common";
import { usePlayerState } from "../../providers/CoreProvider";
import { useLayoutOrientation, useLayoutSettings, useLayoutSize, useVisuals, } from "../../providers/LayoutProvider";
import { useIsChannel, useIsLiveEventWithoutAds, useIsMtvNews247, } from "../../providers/MetadataProvider";
import { LayoutOrientation, LayoutSize } from "../../util/device";
import { useTracksMenu } from "../audio-text-tracks/TracksMenu/TracksMenu";
import { ChannelsButton } from "../ChannelsButton";
import CurrentProgramMetaData from "../CurrentProgramMetaData";
import CurrentTime from "../CurrentTime";
import GoToLiveButton from "../GoToLiveButton";
import GoToStartOverButton from "../GoToStartOverButton";
import LiveStatus from "../LiveStatus";
import PlayPauseButton from "../PlayPauseButton";
import ProgressBar from "../progress-bar/ProgressBar";
import RemainingTime from "../RemainingTime";
import SeekBackwardButton from "../SeekBackwardButton";
import SeekForwardButton from "../SeekForwardButton";
import VolumeController from "../VolumeController/VolumeController";
import { Body, Bottom, ButtonGroup, ButtonsContainer, ControlsContainer, LargeButtonsContainer, ProgressContainer, Top, } from "./MobileControls.styles";
export function MobileControls() {
    const { isLive, isAd, isPauseAd, isFullscreen, playbackMode } = usePlayerState();
    const orientation = useLayoutOrientation();
    const size = useLayoutSize();
    const isChannelAsset = useIsChannel();
    const isMtvNews247 = useIsMtvNews247();
    const isShowingAd = isPauseAd || isAd;
    const isLiveEventWithoutAds = useIsLiveEventWithoutAds();
    const showSeekbuttons = !isShowingAd && !isChannelAsset;
    // startover streams have no DVR window, and doesn't handle pausing well
    const showPlayPauseButton = !isChannelAsset && playbackMode !== PlaybackMode.START_OVER;
    const isSeekableLiveStream = isLive && !isPauseAd && !isChannelAsset;
    const showStartOverButton = isSeekableLiveStream && !isMtvNews247;
    const showMetaData = orientation === LayoutOrientation.PORTRAIT &&
        [LayoutSize.SMALLEST, LayoutSize.SMALL].includes(size);
    const { showMobileMuteButton } = useLayoutSettings();
    const showLiveStatus = isLive || isMtvNews247;
    const showRemainingTime = !showLiveStatus && size !== LayoutSize.SMALLEST;
    const visuals = useVisuals();
    const showChannelsButton = visuals?.includes(VisualElement.CHANNELS_BUTTON);
    const { closeTracksMenu, TracksMenu, TracksButton } = useTracksMenu();
    return (_jsxs(ControlsContainer, { children: [_jsxs(Body, { isFullscreen: isFullscreen, orientation: orientation, onClick: closeTracksMenu, children: [_jsx(Top, { children: showMetaData && _jsx(CurrentProgramMetaData, { hidden: !isFullscreen }) }), _jsxs(LargeButtonsContainer, { children: [showSeekbuttons ? _jsx(SeekBackwardButton, {}) : _jsx("div", {}), showPlayPauseButton && _jsx(PlayPauseButton, {}), showSeekbuttons ? _jsx(SeekForwardButton, {}) : _jsx("div", {})] }), _jsx(Bottom, {})] }), _jsx(ProgressContainer, { children: !isShowingAd && !isChannelAsset && (_jsxs(_Fragment, { children: [_jsx(CurrentTime, {}), _jsx(ProgressBar, {}), showLiveStatus && _jsx(LiveStatus, {}), showRemainingTime && _jsx(RemainingTime, {})] })) }), _jsxs(ButtonsContainer, { children: [_jsx(ButtonGroup, { "$position": "left", children: showStartOverButton && _jsx(GoToStartOverButton, {}) }), _jsxs(ButtonGroup, { "$position": "center", children: [TracksButton && _jsx(TracksButton, {}), showChannelsButton && _jsx(ChannelsButton, {})] }), _jsxs(ButtonGroup, { "$position": "right", children: [isSeekableLiveStream && (_jsx(GoToLiveButton, { liveEventWithoutAds: isLiveEventWithoutAds })), showMobileMuteButton && _jsx(VolumeController, {})] })] }), TracksMenu && _jsx(TracksMenu, {})] }));
}
