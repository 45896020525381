import { AWPAudioTrack, AWPTextTrack, PlaybackMode, PlayerState } from ".";
import { AWPError } from "./AWPError";
import { IAd, IAdBreak } from "./models/ads-models";

export enum CoreEvents {
  LOADING_PLAYBACK = "core:loading_playback",
  LOADED_PLAYBACK = "core:loaded_playback",
  LOAD_PLAYBACK_ERROR = "core:load_playback_error",

  START = "core:start",
  ENDED = "core:ended",
  PAUSED = "core:paused",
  RESUME = "core:resume",
  TIME_UPDATED = "core:time_updated",
  SEEKING = "core:seeking",
  PRE_SEEKING = "core:pre_seeking",
  SEEKED = "core:seeked",
  BUFFERING = "core:buffering",
  BUFFERED = "core:buffered",
  ERROR = "core:error",
  AUTOPLAY_BLOCKED = "core:autoplay_blocked",
  SUCCESSFUL_PLAY = "core:successful_play",
  ABORTED = "core:aborted",

  TEXT_TRACK_CHANGED = "core:text_track_changed",
  SUBTITLE_TEXT_CHANGED = "core:subtitle_text_changed",
  SUBTITLE_TEXT_SIZE_CHANGED = "core:subtitle_text_size_changed",
  AUDIO_TRACK_CHANGED = "core:audio_track_changed",
  BITRATE_CHANGED = "core:bitrate_changed",
  DROPPED_FRAMES = "core:dropped_frames",

  IN_STREAM_METADATA = "core:in_stream_metadata",

  BREAK_START = "core:break_start",
  BREAK_END = "core:break_end",
  AD_LOADING = "core:ad_loading",
  AD_LOADED = "core:ad_loaded",
  AD_START = "core:ad_start",
  AD_PAUSED = "core:ad_paused",
  AD_RESUME = "core:ad_resume",
  AD_TIME_UPDATED = "core:ad_time_updated",
  AD_BUFFERING = "core:ad_buffering",
  AD_BUFFERED = "core:ad_buffered",
  AD_ERROR = "core:ad_error",
  AD_ENDED = "core:ad_ended",
  AD_CLICK = "core:ad_click",
  PAUSE_AD_SHOWN = "core:pause_ad_shown",
  PAUSE_AD_HIDDEN = "core:pause_ad_hidden",

  CHROMECAST_SENDER_LOADED = "core:chromecast_sender_loaded",
  CHROMECAST_INITIALIZED = "core:chromecast_initialized",
  CHROMECAST_CONNECTING = "core:chromecast_connecting",
  CHROMECAST_CONNECTED = "core:chromecast_connected",
  CHROMECAST_DISCONNECTED = "core:chromecast_disconnected",
  CHROMECAST_SESSION_STARTED = "core:chromecast_session_started",
  CHROMECAST_SESSION_ENDED = "core:chromecast_session_ended",
  CHROMECAST_ADMARKERS_LOADED = "core:chromecast_admarkers_loaded",
  CHROMECAST_CONTENT_UPDATED = "core:chromecast_content_updated",
  CHROMECAST_BREAK_START = "core:chromecast_break_start",
  CHROMECAST_BREAK_END = "core:chromecast_break_end",
  CHROMECAST_AD_START = "core:chromecast_ad_start",
  CHROMECAST_AD_END = "core:chromecast_ad_end",
  CHROMECAST_LIVE_STATE_CHANGED = "core:chromecast_live_state_changed",
  CHROMECAST_ERROR = "core:chromecast_error",

  CORE_OPTIONS_CHANGED = "core:core_options_changed",

  VOLUME_CHANGED = "core:volume_changed",
  FULLSCREEN_CHANGED = "core:fullscreen_changed",

  TOGGLE_VOTING = "core:toggle_voting",
  TOGGLE_CHANNELS_OVERLAY = "core:toggle_channels_overlay",
  CREDITS_START = "core:credits_start",
  SKIN_VISIBILITY_CHANGE = "core:skin_visibility_change",

  STATE_CHANGED = "core:state_changed",
  RESET = "core:reset",
  PLAYBACK_MODE_CHANGE = "core:playback_mode_change",
  PLAYBACK_MODE_SET = "core:playback_mode_set",
  USER_ACTIVE_CONFIRM = "core:user_active_confirm",
  USER_ACTIVE_DECLINE = "core:user_active_decline",

  STREAM_INFO_UPDATED = "core:stream_info_updated",
  DRM_LICENSE_ERROR = "core:drm_license_error",

  SESSION_CREATED = "core:session_created",

  CDN_CHANGED = "core:cdn_changed",
}

export type PreSeekingPayload = { currentTime: number };
export type NoPayload = undefined;
export type OptionalTrackingPayload = { tracking?: boolean };
export type ErrorPayload = { error: AWPError };
export type TimePayload = {
  currentTime: number;
  duration: number;
  utcCurrentTimeMs?: number;
};
export type BufferSeekingPayload = TimePayload & { playing: boolean };
export type VolumePayload = { volume: number; muted: boolean };
export type FullscreenPayload = { fullscreen: boolean };

export type LoadedPlaybackPayload = TimePayload &
  VolumePayload & {
    adBreaks: IAdBreak[];
    isLive: boolean;
  };

export type PlaybackErrorPayload = TimePayload & ErrorPayload;
export type DrmLicenseErrorPayload = TimePayload & ErrorPayload;

export type AdBreakPayload = OptionalTrackingPayload & { adBreak: IAdBreak };
export type AdPayload = { ad: IAd };
export type AdResumePayload = {
  ad?: IAd;
} & TimePayload;
export type AdClickPayload = { url: string };

export type TextTrackPayload = {
  activeTextTrack?: AWPTextTrack;
  textTracks?: AWPTextTrack[];
};

export type SubTitleTextPayload = {
  text: string;
};

export type SubTitleTextSizePayload = {
  size: number;
};

export type AudioTrackPayload = {
  activeAudioTrack?: AWPAudioTrack;
  audioTracks?: AWPAudioTrack[];
};
export type BitratePayload = { width: number; height: number; bitrate: number };
export type DroppedFramesPayload = { droppedFrames: number };

export type InStreamMetadataPayload = { title: string };

export type StateChangedPayload = { state: PlayerState };

export type ChromeCastSessionStartedPayload = {
  contentId: string;
};

export type ChromecastSessionEndedPayload = {
  contentId: string;
  currentTime: number;
};

export type ChromecastAdMarkersLoadedPayload = {
  adBreaks: IAdBreak[];
};

export type ChromecastContentUpdatedPayload = {
  contentId: string;
};

export type ChromecastLiveStatePayload = {
  isLive?: boolean;
};

export type ChromecastErrorPayload = {
  error?: {
    code: string;
    message: string;
    origin?: string;
    fatal?: boolean;
  };
};

export type CreditsStartPayload = {
  creditsStartTime: number;
};

export type PlaybackModePayload = {
  playbackMode: PlaybackMode;
  originStartTime?: number;
};

export type StreamInfoPayload = {
  startTime?: number;
  duration?: number;
  // currentTime is not in the original payload. It is set from the TIME_UPDATED handler
  currentTime?: number;
  ended?: boolean;
};

export type SkinVisibilityChangePayload = {
  visible: boolean;
};

export type SessionCreatedPayload = { sessionId: string };
export type CdnChangedPayload = { cdn: string };

export interface CoreEventsMap {
  [CoreEvents.LOADING_PLAYBACK]: NoPayload;
  [CoreEvents.LOADED_PLAYBACK]: LoadedPlaybackPayload;
  [CoreEvents.LOAD_PLAYBACK_ERROR]: ErrorPayload;

  [CoreEvents.START]: TimePayload;
  [CoreEvents.ENDED]: TimePayload;
  [CoreEvents.PAUSED]: TimePayload;
  [CoreEvents.RESUME]: TimePayload;

  [CoreEvents.TIME_UPDATED]: TimePayload;
  [CoreEvents.SEEKING]: BufferSeekingPayload;
  [CoreEvents.PRE_SEEKING]: PreSeekingPayload;
  [CoreEvents.SEEKED]: BufferSeekingPayload;
  [CoreEvents.BUFFERING]: BufferSeekingPayload;
  [CoreEvents.BUFFERED]: BufferSeekingPayload;
  [CoreEvents.ERROR]: PlaybackErrorPayload | DrmLicenseErrorPayload;
  [CoreEvents.AUTOPLAY_BLOCKED]: NoPayload;
  [CoreEvents.SUCCESSFUL_PLAY]: NoPayload;
  [CoreEvents.ABORTED]: NoPayload;

  [CoreEvents.TEXT_TRACK_CHANGED]: TextTrackPayload;
  [CoreEvents.SUBTITLE_TEXT_CHANGED]: SubTitleTextPayload;
  [CoreEvents.SUBTITLE_TEXT_SIZE_CHANGED]: SubTitleTextSizePayload;
  [CoreEvents.AUDIO_TRACK_CHANGED]: AudioTrackPayload;
  [CoreEvents.BITRATE_CHANGED]: BitratePayload;
  [CoreEvents.DROPPED_FRAMES]: DroppedFramesPayload;

  [CoreEvents.IN_STREAM_METADATA]: InStreamMetadataPayload;

  [CoreEvents.BREAK_START]: AdBreakPayload;
  [CoreEvents.BREAK_END]: OptionalTrackingPayload;
  [CoreEvents.AD_LOADING]: AdPayload;
  [CoreEvents.AD_LOADED]: AdPayload;
  [CoreEvents.AD_START]: AdPayload;
  [CoreEvents.AD_PAUSED]: TimePayload;
  [CoreEvents.AD_RESUME]: AdResumePayload;
  [CoreEvents.AD_TIME_UPDATED]: TimePayload;
  [CoreEvents.AD_BUFFERING]: BufferSeekingPayload;
  [CoreEvents.AD_BUFFERED]: BufferSeekingPayload;
  [CoreEvents.AD_ERROR]: ErrorPayload;
  [CoreEvents.AD_ENDED]: NoPayload;
  [CoreEvents.PAUSE_AD_SHOWN]: NoPayload;
  [CoreEvents.PAUSE_AD_HIDDEN]: NoPayload;
  [CoreEvents.AD_CLICK]: AdClickPayload;

  [CoreEvents.CHROMECAST_SENDER_LOADED]: NoPayload;
  [CoreEvents.CHROMECAST_INITIALIZED]: NoPayload;
  [CoreEvents.CHROMECAST_CONNECTING]: NoPayload;
  [CoreEvents.CHROMECAST_CONNECTED]: NoPayload;
  [CoreEvents.CHROMECAST_DISCONNECTED]: NoPayload;
  [CoreEvents.CHROMECAST_SESSION_STARTED]: NoPayload;
  [CoreEvents.CHROMECAST_SESSION_ENDED]: ChromecastSessionEndedPayload;
  [CoreEvents.CHROMECAST_ADMARKERS_LOADED]: ChromecastAdMarkersLoadedPayload;
  [CoreEvents.CHROMECAST_CONTENT_UPDATED]: ChromecastContentUpdatedPayload;
  [CoreEvents.CHROMECAST_BREAK_START]: AdBreakPayload;

  [CoreEvents.CHROMECAST_BREAK_END]: NoPayload;
  [CoreEvents.CHROMECAST_AD_START]: AdPayload;
  [CoreEvents.CHROMECAST_AD_END]: NoPayload;
  [CoreEvents.CHROMECAST_LIVE_STATE_CHANGED]: ChromecastLiveStatePayload;
  [CoreEvents.CHROMECAST_ERROR]: ChromecastErrorPayload;

  [CoreEvents.CORE_OPTIONS_CHANGED]: NoPayload;

  [CoreEvents.VOLUME_CHANGED]: VolumePayload;
  [CoreEvents.FULLSCREEN_CHANGED]: FullscreenPayload;

  [CoreEvents.TOGGLE_VOTING]: NoPayload;
  [CoreEvents.TOGGLE_CHANNELS_OVERLAY]: NoPayload;
  [CoreEvents.CREDITS_START]: CreditsStartPayload;
  [CoreEvents.SKIN_VISIBILITY_CHANGE]: SkinVisibilityChangePayload;

  [CoreEvents.STATE_CHANGED]: StateChangedPayload;
  [CoreEvents.RESET]: NoPayload;
  [CoreEvents.PLAYBACK_MODE_CHANGE]: PlaybackModePayload;
  [CoreEvents.PLAYBACK_MODE_SET]: PlaybackModePayload;
  [CoreEvents.USER_ACTIVE_CONFIRM]: NoPayload;
  [CoreEvents.USER_ACTIVE_DECLINE]: NoPayload;
  [CoreEvents.STREAM_INFO_UPDATED]: StreamInfoPayload;
  [CoreEvents.DRM_LICENSE_ERROR]: DrmLicenseErrorPayload;
  [CoreEvents.SESSION_CREATED]: SessionCreatedPayload;

  [CoreEvents.CDN_CHANGED]: CdnChangedPayload;
}

export enum AdTrackingEvent {
  START = "start",
  IMPRESSION = "impression",
  EXPAND = "expand",
  COLLAPSE = "collapse",
  MUTE = "mute",
  UNMUTE = "unmute",
  PAUSE = "pause",
  RESUME = "resume",
  REWIND = "rewind",
  CLOSE = "close",
  COMPLETE = "complete",

  FIRST_QUARTILE = "firstQuartile",
  MIDPOINT = "midpoint",
  THIRD_QUARTILE = "thirdQuartile",

  CLICK_THROUGH = "clickThrough",

  ERROR = "error",
}

export enum AdBreakTrackingEvent {
  BREAK_START = "breakStart",
  BREAK_END = "breakEnd",
}
