import { AssetType, AWPError, ERROR_CATEGORY, ErrorLevel, getSelectedDrm, getUserId, PLAYER_ERROR, resolveMediaType, supportedFormats, } from "@tv4/avod-web-player-common";
import { SKIPPABLE_REGION_MINIMUM_DURATION } from "../constants/skippable-regions";
const getEndCreditsStart = (playbackItems) => {
    const endCreditsStartOrEndCreditsItem = playbackItems.find((item) => item.categoryId === 8 || item.categoryId === 88);
    return endCreditsStartOrEndCreditsItem?.timeBegin;
};
const getSkippableRegions = (playbackItems) => {
    return playbackItems.filter((item) => (item.categoryId === 83 || item.categoryId === 85) &&
        item.timeEnd - item.timeBegin >= SKIPPABLE_REGION_MINIMUM_DURATION);
};
export function mapPlaybackApiMediaToMedia(options) {
    let manifestUrl;
    const { playbackItem } = options.playbackAsset;
    if (!playbackItem) {
        return;
    }
    if (options.startOver) {
        manifestUrl = playbackItem.startoverManifestUrl;
    }
    else if (options.origin && playbackItem.originUrl) {
        manifestUrl = playbackItem.originUrl;
    }
    else {
        manifestUrl = playbackItem.manifestUrl;
    }
    if (!manifestUrl) {
        return;
    }
    const type = resolveMediaType(manifestUrl);
    const { license } = playbackItem;
    if (license?.type) {
        license.type = license.type.replace("drmtoday.", "");
    }
    return {
        type,
        isLive: options.playbackAsset.metadata.isLive,
        isStartOver: manifestUrl === playbackItem.startoverManifestUrl,
        isStitched: !options.origin && playbackItem.isStitched,
        manifestUrl,
        accessUrl: playbackItem.accessUrl,
        license,
        mediaUri: options.playbackAsset.mediaUri,
        thumbnails: playbackItem.thumbnails?.reduce((acc, thumbnail) => ({
            ...acc,
            [thumbnail.type]: thumbnail,
        }), {}),
    };
}
export async function mapPlaybackAssetToMetadata({ asset, accessToken, }) {
    let type = AssetType.VOD;
    if (asset.metadata.isClip) {
        type = AssetType.CLIP;
    }
    if (asset.metadata.isLive) {
        type = AssetType.LIVE;
    }
    const trackingData = {
        ...asset.trackingData.videoplaza,
    };
    const assetId = asset.metadata.id;
    return {
        assetId,
        userId: accessToken ? getUserId(accessToken) : undefined,
        asset: {
            type,
            title: asset.metadata.title,
            programNid: asset.metadata.programNid,
            seriesTitle: asset.metadata.seriesTitle,
            episodeTitle: asset.metadata.episodeTitle,
            seasonNumber: asset.metadata.seasonNumber,
            episodeNumber: asset.metadata.episodeNumber,
            categories: asset.metadata.categories,
            duration: asset.metadata.duration,
            image: asset.metadata.image,
            assetId,
            endCreditsStart: getEndCreditsStart(asset.items),
            contentRating: asset.contentRating,
            playbackApiAssetType: asset.metadata.type,
            vimondId: asset.trackingData.youbora.id,
            hideAds: asset.metadata.hideAds,
            startOver: asset.metadata.startOver,
            skippableRegions: getSkippableRegions(asset.items),
            // asset.playbackItem is exclusive to the play response, and not available in the metadata response,
            // which also passes through this function, so this needs to be handled by the player
            ...("playbackItem" in asset && {
                isStitched: asset.playbackItem?.isStitched,
                streaminfoUrl: asset.playbackItem?.streaminfoUrl,
            }),
        },
        adBreak: {
            ads: asset.items
                // only categoryIds 6 and 102 are considered to be ad breaks
                .filter((item) => [6, 102].includes(item.categoryId))
                .map((item) => ({
                categoryId: item.categoryId,
                categoryName: item.categoryName,
                timeBegin: item.timeBegin,
            })),
            bumpers: {
                ...asset.bumpers,
            },
            trackingData,
        },
        tracking: asset.trackingData,
        ...("userTier" in asset && {
            userTier: asset.userTier,
        }),
    };
}
export async function getMetadataAndMedia({ assetId, service, playbackApi, playerMode, mutableState, }) {
    const drm = await getSelectedDrm();
    if (mutableState.cancelled)
        return;
    const accessToken = playbackApi.getAccessToken();
    const playbackAsset = await playbackApi.playRequest(assetId, {
        drm,
        protocol: supportedFormats(),
        service,
        playerMode,
    });
    if (mutableState.cancelled)
        return;
    const metadata = await mapPlaybackAssetToMetadata({
        asset: playbackAsset,
        accessToken,
    });
    if (mutableState.cancelled)
        return;
    const media = mapPlaybackApiMediaToMedia({
        playbackAsset,
    });
    const startOverMedia = mapPlaybackApiMediaToMedia({
        playbackAsset,
        startOver: true,
    });
    const originMedia = mapPlaybackApiMediaToMedia({
        playbackAsset,
        origin: true,
    });
    if (!media) {
        throw new AWPError({
            context: "player",
            message: "media not found",
            category: ERROR_CATEGORY.DEFAULT,
            code: PLAYER_ERROR.MISSING_DATA,
            errorLevel: ErrorLevel.PLAYER,
        });
    }
    return { metadata, media, startOverMedia, originMedia };
}
export async function getAssetMetadata({ assetId, service, playbackApi, mutableState, }) {
    const accessToken = playbackApi.getAccessToken();
    const playbackAsset = await playbackApi.assetDataRequest(assetId, {
        service,
    });
    if (mutableState.cancelled)
        return;
    return await mapPlaybackAssetToMetadata({
        asset: playbackAsset,
        accessToken,
    });
}
