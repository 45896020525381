/**
 * Player Errors
 * These groups & types are shared between the different platforms.
 *
 * Error handling MUST be kept in sync with other players (iOS, Android ...)
 */
export const generateErrorCode = (domain, code) => `${domain.toLowerCase()}[${code}]`;
export var ERROR_CATEGORY;
(function (ERROR_CATEGORY) {
    ERROR_CATEGORY["PLAYBACK_API"] = "PlaybackApi";
    ERROR_CATEGORY["API"] = "API";
    ERROR_CATEGORY["NETWORK"] = "Network";
    ERROR_CATEGORY["ADS"] = "Ads";
    ERROR_CATEGORY["DRM"] = "DRM";
    ERROR_CATEGORY["MEDIA"] = "Media";
    ERROR_CATEGORY["USER"] = "User";
    ERROR_CATEGORY["DEFAULT"] = "Default";
})(ERROR_CATEGORY || (ERROR_CATEGORY = {}));
export var PLAYER_ERROR;
(function (PLAYER_ERROR) {
    PLAYER_ERROR["GENERIC"] = "Generic";
    PLAYER_ERROR["SEQUENCING"] = "SequencingError";
    PLAYER_ERROR["WRONG_INPUT"] = "WrongInput";
    PLAYER_ERROR["MISSING_DATA"] = "MissingData";
    PLAYER_ERROR["UNSUPPORTED_DEVICE"] = "UnsupportedDevice";
    PLAYER_ERROR["AD_BLOCKER"] = "AdBlocker";
    PLAYER_ERROR["POTENTIAL_AD_BLOCKER"] = "PotentialAdBlocker";
    PLAYER_ERROR["AD_TIMEOUT"] = "AdTimeout";
    PLAYER_ERROR["AD_STALLED"] = "AdStalled";
    PLAYER_ERROR["AD_PERFORMANCE"] = "AdPerformance";
    PLAYER_ERROR["USER_INACTIVITY_STALE_SESSION"] = "USER_INACTIVITY_STALE_SESSION";
})(PLAYER_ERROR || (PLAYER_ERROR = {}));
export var DRM_ERROR;
(function (DRM_ERROR) {
    DRM_ERROR["DRM_GENERIC"] = "DrmGeneric";
})(DRM_ERROR || (DRM_ERROR = {}));
export var MEDIA_ERROR;
(function (MEDIA_ERROR) {
    MEDIA_ERROR["UNKNOWN"] = "Unknown";
})(MEDIA_ERROR || (MEDIA_ERROR = {}));
export var API_ERROR;
(function (API_ERROR) {
    API_ERROR["YOSPACE"] = "YospaceFailure";
    API_ERROR["UNAUTHORIZED"] = "UNAUTHORIZED";
    API_ERROR["STREAM_NOT_STARTED"] = "stream_not_started";
    API_ERROR["SESSION_NOT_AUTHENTICATED"] = "SESSION_NOT_AUTHENTICATED";
    API_ERROR["ASSET_PLAYBACK_INVALID_GEO_LOCATION"] = "ASSET_PLAYBACK_INVALID_GEO_LOCATION";
    API_ERROR["ASSET_PLAYBACK_PROXY_BLOCKED"] = "ASSET_PLAYBACK_PROXY_BLOCKED";
    API_ERROR["STREAM_LIMIT_SINGLE_STREAM_VIOLATION"] = "STREAM_LIMIT_SINGLE_STREAM_VIOLATION";
    API_ERROR["STREAM_LIMIT_QUOTA_EXCEEDED"] = "STREAM_LIMIT_QUOTA_EXCEEDED";
    API_ERROR["ASSET_PLAYBACK_PORTABILITY_REQUIRED_FOR_GEO_LOCATION"] = "ASSET_PLAYBACK_PORTABILITY_REQUIRED_FOR_GEO_LOCATION";
    API_ERROR["LOGIN_REQUIRED"] = "LOGIN_REQUIRED";
    API_ERROR["MISSING_SUBSCRIPTION"] = "MISSING_SUBSCRIPTION";
    API_ERROR["DRM_NOT_ACCEPTABLE"] = "DRM_NOT_ACCEPTABLE";
})(API_ERROR || (API_ERROR = {}));
/**
 * User errors are not reported to KPI tracker instance. They are instead handled in the client.
 */
export var USER_ERROR;
(function (USER_ERROR) {
    USER_ERROR["GEO_LOCATION"] = "ASSET_PLAYBACK_INVALID_GEO_LOCATION";
    USER_ERROR["GEO_LOCATION_PORTABILITY"] = "ASSET_PLAYBACK_PORTABILITY_REQUIRED_FOR_GEO_LOCATION";
    USER_ERROR["UNAUTHORIZED"] = "UNAUTHORIZED";
    USER_ERROR["NOT_AUTHENTICATED"] = "SESSION_NOT_AUTHENTICATED";
    USER_ERROR["PROXY_BLOCKED"] = "ASSET_PLAYBACK_PROXY_BLOCKED";
    USER_ERROR["LOGIN_REQUIRED"] = "LOGIN_REQUIRED";
    USER_ERROR["MISSING_SUBSCRIPTION"] = "MISSING_SUBSCRIPTION";
    USER_ERROR["STREAM_LIMIT_QUOTA_EXCEEDED"] = "STREAM_LIMIT_QUOTA_EXCEEDED";
    USER_ERROR["STREAM_LIMIT_SINGLE_STREAM_VIOLATION"] = "STREAM_LIMIT_SINGLE_STREAM_VIOLATION";
    USER_ERROR["USER_INACTIVITY_STALE_SESSION"] = "USER_INACTIVITY_STALE_SESSION";
    USER_ERROR["DRM_NOT_ACCEPTABLE"] = "DRM_NOT_ACCEPTABLE";
})(USER_ERROR || (USER_ERROR = {}));
