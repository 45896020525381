import {
  getProfileId,
  isMtv,
  parseJwtToken,
  storage,
} from "@tv4/avod-web-player-common";

import { developmentConfig, hasMTVInLocation } from "./utils";

export const getAccessTokenByRefreshToken = async (
  refreshToken: string,
  isMTV: boolean,
  profileId: string | null
) => {
  const url = new URL(
    `${
      isMTV
        ? developmentConfig.MTVAuthEndpoint
        : developmentConfig.TV4AuthEndpoint
    }/refresh`
  );
  const body = JSON.stringify({
    refresh_token: refreshToken,
    client_id: "nordic-web-player",
    ...(profileId ? { profile_id: profileId } : {}),
  });

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  };

  return fetch(url.toString(), options)
    .then((response) => response.json())
    .then((result) => {
      return result.access_token;
    });
};

export const accessTokenIsExpired = (accessToken: string): boolean => {
  const expiryTime = parseJwtToken(accessToken)?.exp || 0;
  return (
    expiryTime - Math.floor(Date.now() / 1000) <
    developmentConfig.refreshTimeOut
  );
};

export const getLoggedInUserId = () => {
  return storage.getSessionData("userId") || undefined;
};

export const getAccessToken = (): string | undefined => {
  return storage.getSessionData<string>("accessToken");
};

export const clearLogin = () => {
  storage.removeSessionData("accessToken");
  storage.removeSessionData("refreshToken");
  storage.removeSessionData("userId");
  storage.removeSessionData("accountType");
};

type CredentialTokens = {
  accessToken: string;
  refreshToken: string;
};

export const getCredentialTokens = async (): Promise<
  CredentialTokens | undefined
> => {
  const locationUrl = new URL(window.location.href);
  const refreshTokenOverride = locationUrl.searchParams.get("refreshToken");
  const profileId = locationUrl.searchParams.get("profileId") || "default";
  console.debug("profileId to include in accessToken: ", profileId);

  if (refreshTokenOverride) {
    const accessTokenFromOverride = await getAccessTokenByRefreshToken(
      refreshTokenOverride,
      hasMTVInLocation(),
      profileId
    );
    return {
      accessToken: accessTokenFromOverride,
      refreshToken: refreshTokenOverride,
    };
  }
  const accessToken = getAccessToken();
  const refreshToken = storage.getSessionData<string>("refreshToken");

  if (!accessToken || !refreshToken) return undefined;

  const profileIdInToken = getProfileId(accessToken);
  if (!accessTokenIsExpired(accessToken) && profileId === profileIdInToken) {
    return { accessToken, refreshToken };
  }

  const newAccessToken = await getAccessTokenByRefreshToken(
    refreshToken,
    isMtv(),
    profileId
  );
  storage.setSessionData("accessToken", newAccessToken);

  return { accessToken: newAccessToken, refreshToken };
};
