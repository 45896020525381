import { AdServerOptions } from "./ads-models";
import { AssetMarkers, Thumbnail, UserTier } from "./playback-api-models";
import {
  FacebookTrackingMetadata,
  GATrackingMetadata,
  LinkPulseTrackingMetadata,
  NielsenTrackingMetadata,
  NpawTrackingMetadata,
} from "./tracking-metadata-models";

export enum AssetType {
  VOD = "vod",
  CLIP = "clip",
  LIVE = "live",
}

export enum PlaybackApiAssetType {
  CHANNEL = "channel",
  CLIP = "clip",
  EPISODE = "episode",
  LIVE = "live",
  MOVIE = "movie",
  SPORTVIDEO = "sportvideo",
}

export enum DRMFormat {
  WIDEVINE = "widevine",
  PLAYREADY = "playready",
  FAIRPLAY = "fairplay",
}

export enum MediaType {
  DASH = "dash",
  HLS = "hls",
  MP4 = "mp4",
}

export { UserTier };

export interface ContentRating {
  ageRating: string;
  system: string;
  descriptors: string[];
  productPlacement: boolean;
}

export interface Asset {
  type: AssetType;
  title: string;
  seriesTitle?: string;
  episodeTitle?: string;
  seasonNumber?: number;
  episodeNumber?: number;
  programNid: string;
  categories: string[];
  duration: number;
  image?: string;
  assetId: string;
  endCreditsStart?: number;
  contentRating?: ContentRating;
  playbackApiAssetType?: PlaybackApiAssetType;
  hideAds?: boolean;
  startOver?: boolean;
  vimondId?: string;
  skippableRegions?: AssetMarkers[];
  isStitched?: boolean;
  streaminfoUrl?: string;
}

export interface Ad {
  categoryId: number;
  categoryName: string;
  timeBegin: number;
}

export interface Metadata {
  assetId: string;
  userId?: string;
  asset: Asset;
  adBreak: {
    ads: Ad[];
    bumpers: {
      start: string;
      lowresStart: string;
      end: string;
      prerollEnd: string;
    };
    trackingData: AdServerOptions;
  };
  tracking: {
    linkPulse: LinkPulseTrackingMetadata;
    nielsen: NielsenTrackingMetadata;
    youbora: NpawTrackingMetadata;
    GA: GATrackingMetadata;
    facebook: FacebookTrackingMetadata;
    videoplaza: AdServerOptions;
  };
  userTier?: UserTier;
}

export interface License {
  type: DRMFormat;
  castlabsToken: string;
  castlabsServer: string;
}

export interface FairplayLicense extends License {
  castlabsAssetId: string;
  castlabsCertServer: string;
}

export interface ThumbnailVariants {
  tv?: Thumbnail;
  desktop?: Thumbnail;
  tablet?: Thumbnail;
  mobile?: Thumbnail;
}

export interface Media {
  type: MediaType;
  isLive: boolean;
  isStartOver: boolean;
  isStitched: boolean;
  manifestUrl: string;
  accessUrl?: string;
  license?: License | FairplayLicense;
  mediaUri?: string;
  thumbnails?: ThumbnailVariants;
}

export enum Service {
  TV4Play = "tv4play",
  FOTBOLLSKANALEN = "Fotbollskanalen", // subservice of TV4PLAY
  KOKET = "koket", // subservice of TV4PLAY
  MTV = "mtv",
  MTVUUTISET = "mtvuutiset", // subservice of MTV
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values -- See comment below
  DEFAULT = "tv4play", // Should be the same value as Service.TV4PLAY!
}

export enum WireTrackingService {
  TV4Play = "tv4play",
  MTV = "mtv",
}
