import {
  isFirefox,
  isNativeHlsSupported,
} from "@tv4/avod-web-player-device-capabilities";

import { Media, MediaType, Metadata } from "../models/data-models";

export enum EngineName {
  NATIVE = "web-native",
  HLS_JS = "hls.js",
  SHAKA = "shaka-player",
}

const getHlsEngineName = (media: Media, metadata: Metadata): EngineName => {
  const isLive = metadata.asset.type === "live";

  /*
  Will return on the following browsers as of 2023-08-24

  Safari VOD DRM
  Safari VOD NON-DRM
  Safari LIVE DRM
  Safari LIVE NON-DRM
   */
  if (isNativeHlsSupported(isLive)) {
    return EngineName.NATIVE;
  }

  /*
  Will return on the following browsers as of 2023-08-24

  Chrome VOD DRM
  Chrome Live DRM
  Edge VOD DRM
  Edge Live DRM
   */

  if (media.license && !isFirefox()) {
    return EngineName.SHAKA;
  }

  /*
  Will return on the following browsers as of 2023-08-24

  Chrome VOD NON-DRM
  Chrome LIVE NON-DRM
  Firefox VOD NON-DRM
  Firefox LIVE NON-DRM
  Firefox VOD DRM
  Firefox Live DRM
   */

  return EngineName.HLS_JS;
};

const getDashEngineName = (): EngineName => {
  /*
  Will return on the following browsers as of 2023-08-24 (only if playing DASH)

  Chrome VOD DRM
  Firefox VOD DRM
   */
  return EngineName.SHAKA;
};

export const getEngineName = (
  media: Media | undefined,
  metadata: Metadata | undefined
): EngineName | null => {
  if (!media || !metadata) {
    return null;
  }

  const { type } = media;

  switch (type) {
    case MediaType.HLS:
      return getHlsEngineName(media, metadata);
    case MediaType.DASH:
      return getDashEngineName();
    default:
      return null;
  }
};
